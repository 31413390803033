import { doc, getDoc } from "firebase/firestore";
import { db, storage } from "./firebase";
import { getDownloadURL, ref } from "firebase/storage";

export const timeToString = (date) => {
  return (
    date.toDate().getHours() +
    ":" +
    date.toDate().getMinutes().toString().padStart(2, "0")
  );
};

export const convertNumToDayOfWeek = (num) => {
  switch (num) {
    case 0:
      return "יום ראשון";
    case 1:
      return "יום שני";
    case 2:
      return "יום שלישי";
    case 3:
      return "יום רביעי";
    case 4:
      return "יום חמישי";
    case 5:
      return "יום שישי";
    case 6:
      return "יום שבת";
    default:
      return "error";
  }
};

export const getLogoURL = async (teamName) => {
  const teamSnap = await getDoc(doc(db, "Teams", teamName));
  return await getDownloadURL(ref(storage, teamSnap.data().logo));
};

export const calculateTotalUsersInStation = (registeredUsersInStation) => {
  return Object.values(registeredUsersInStation).reduce((a, b) => a + b, 0);
};

export const calculateTotalUsersOneWay = (registeredUsersOneWay) => {
  return Object.values(registeredUsersOneWay)
    .map((registeredUsersInStation) =>
      calculateTotalUsersInStation(registeredUsersInStation)
    )
    .reduce((a, b) => a + b, 0);
};

export const calculateMembersOneWay = (
  registeredUsersOneWay,
  registeredUsers
) => {
  return Object.entries(
    Object.values(registeredUsersOneWay).reduce((sums, usersInStation) => {
      Object.entries(usersInStation).forEach(
        ([user, numRegistered]) =>
          (sums[user] = (sums[user] || 0) + numRegistered)
      );
      return sums;
    }, {})
  )
    .map(([user, numRegistered]) =>
      Math.min(numRegistered, registeredUsers[user].numMembers)
    )
    .reduce((a, b) => a + b, 0);
};
