import style from "./RegisteredUsers.module.less";
import { useLocation } from "react-router-dom";
import { TreeView, TreeItem } from "@mui/lab";
import { useStations } from "../utils/useStations";
import {
  ChevronLeft,
  ExpandMore,
  PeopleAlt,
  Phone,
  Loyalty,
} from "@mui/icons-material";
import {
  calculateMembersOneWay,
  calculateTotalUsersInStation,
  calculateTotalUsersOneWay,
} from "../utils/utils";

function RegisteredUsers() {
  const { state } = useLocation();
  const busData = state;
  const stations = useStations();
  const renderOneWay = (registeredUsersOneWay, str) =>
    stations.map((station) => (
      <TreeItem
        nodeId={station.name + " " + str}
        key={station.name + " " + str}
        label={
          <span
            className={
              busData.openStations.includes(station.name)
                ? style.label
                : style.closedLabel
            }
          >
            {!busData.openStations.includes(station.name) && "תחנה סגורה - "}
            {station.name +
              ` (${calculateTotalUsersInStation(
                registeredUsersOneWay[station.name]
              )})`}
          </span>
        }
        style={{
          paddingLeft: 0,
          paddingTop: 5,
          paddingBottom: 5,
          marginRight: 20,
        }}
      >
        {Object.entries(registeredUsersOneWay[station.name]).map(
          ([user, numRegistered]) => (
            <div key={user} className={style.userRow}>
              <span className={style.username}>
                {busData.registeredUsers[user].name}
              </span>
              <a
                href={`tel:${busData.registeredUsers[user].phone}`}
                className={style.phone}
              >
                <Phone />
              </a>
              <span className={style.numRegistered}>
                {numRegistered}
                <PeopleAlt />
              </span>
              <span className={style.numRegistered}>
                {busData.registeredUsers[user].numMembers}
                <Loyalty />
              </span>
            </div>
          )
        )}
      </TreeItem>
    ));

  return (
    <div className={style.registeredUsers}>
      <div className={style.header}>
        נוסעים רשומים{"\n"}
        {busData.opponentName} {busData.gameDate}
      </div>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronLeft />}
        style={{ paddingLeft: 0, paddingTop: 5, paddingBottom: 5 }}
      >
        <TreeItem
          nodeId={"0"}
          label={
            <span className={style.label}>{`הלוך (${calculateMembersOneWay(
              busData.registeredUsersToGame,
              busData.registeredUsers
            )}/${calculateTotalUsersOneWay(
              busData.registeredUsersToGame
            )})`}</span>
          }
        >
          {renderOneWay(busData.registeredUsersToGame, "הלוך")}
        </TreeItem>
        <TreeItem
          nodeId={"1"}
          label={
            <span className={style.label}>{`חזור (${calculateMembersOneWay(
              busData.registeredUsersFromGame,
              busData.registeredUsers
            )}/${calculateTotalUsersOneWay(
              busData.registeredUsersFromGame
            )})`}</span>
          }
        >
          {renderOneWay(busData.registeredUsersFromGame, "חזור")}
        </TreeItem>
      </TreeView>
    </div>
  );
}

export default RegisteredUsers;
