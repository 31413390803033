// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_NAME + ".firebaseapp.com",
  projectId: process.env.REACT_APP_NAME,
  storageBucket: process.env.REACT_APP_NAME + ".appspot.com",
  messagingSenderId: process.env.REACT_APP_MSID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

export const storage = getStorage(app);
