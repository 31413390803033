import {
  CircularProgress,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Link } from "react-router-dom";
import style from "./SubmitDialog.module.less";
import { calculateTotalUsersOneWay } from "../utils/utils";
function SubmitDialog({
  submitDialogOpen,
  setSubmitDialogOpen,
  personalDetails,
  busDetails,
}) {
  const [confirmedDetails, setConfirmedDetails] = useState(false);
  const [registeredSuccessfully, setRegisteredSuccessfully] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tooManyPassengers, setTooManyPassengers] = useState(false);

  const closeSubmit = () => {
    if (!registeredSuccessfully) {
      setSubmitDialogOpen(false);
      setConfirmedDetails(false);
    }
  };
  const registerToBus = async () => {
    setLoading(true);
    const docSnap = await getDoc(doc(db, "Buses", busDetails.busID));
    const userId = personalDetails.email.replaceAll(".", "@");
    const previousDetails =
      docSnap.data().registered_users[
        personalDetails.email.replaceAll(".", "@")
      ];
    if (docSnap.exists()) {
      const busData = docSnap.data();
      const maxPassengers = busData.max_passengers;
      let totalPassengersToGame = calculateTotalUsersOneWay(
          busData.registered_users_to_game,
          busData.open_stations
        ),
        totalPassengersFromGame = calculateTotalUsersOneWay(
          busData.registered_users_from_game,
          busData.open_stations
        );

      if (previousDetails) {
        totalPassengersToGame =
          previousDetails.boardingStation === "none"
            ? totalPassengersToGame
            : totalPassengersToGame - previousDetails.numPassengers;
        totalPassengersFromGame =
          previousDetails.alightingStation === "none"
            ? totalPassengersFromGame
            : totalPassengersFromGame - previousDetails.numPassengers;
      }

      totalPassengersToGame =
        personalDetails.boardingStation === "none"
          ? totalPassengersToGame
          : totalPassengersToGame + personalDetails.numPassengers;
      totalPassengersFromGame =
        personalDetails.alightingStation === "none"
          ? totalPassengersFromGame
          : totalPassengersFromGame + personalDetails.numPassengers;

      if (
        totalPassengersToGame <= maxPassengers &&
        totalPassengersFromGame <= maxPassengers
      ) {
        const registeredToGame = busData.registered_users_to_game;
        const registeredFromGame = busData.registered_users_from_game;

        if (previousDetails) {
          if (
            previousDetails.boardingStation !==
              personalDetails.boardingStation &&
            previousDetails.boardingStation !== "none"
          ) {
            delete registeredToGame[previousDetails.boardingStation][userId];
          }
          if (
            previousDetails.alightingStation !==
              personalDetails.alightingStation &&
            previousDetails.alightingStation !== "none"
          ) {
            delete registeredFromGame[previousDetails.alightingStation][userId];
          }
        }
        if (personalDetails.boardingStation !== "none") {
          registeredToGame[personalDetails.boardingStation][userId] =
            personalDetails.numPassengers;
        }
        if (personalDetails.alightingStation !== "none") {
          registeredFromGame[personalDetails.alightingStation][userId] =
            personalDetails.numPassengers;
        }

        await updateDoc(doc(db, "Buses", busDetails.busID), {
          [`registered_users.${userId}`]: personalDetails,
          registered_users_to_game: registeredToGame,
          registered_users_from_game: registeredFromGame,
        });
        setRegisteredSuccessfully(true);
      } else {
        setTooManyPassengers(true);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog open={submitDialogOpen} onClose={closeSubmit}>
      {loading && (
        <div className={style.LoadingDialog}>
          <CircularProgress />
        </div>
      )}
      {!confirmedDetails && !registeredSuccessfully && !loading && (
        <div className={style.SubmitDialog}>
          <div className={style.DialogTitle}>
            <p>סיכום פרטים</p>
            <p>
              {busDetails.opponentName} -{" " + busDetails.gameDate}
            </p>
          </div>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell variant="head" align="right">
                    שעת המשחק
                  </TableCell>
                  <TableCell align="right">{busDetails.gameTime}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" align="right">
                    שעת יציאה
                  </TableCell>
                  <TableCell align="right">
                    {busDetails.busTime[busDetails.openStations[0]]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" align="right">
                    שם מלא
                  </TableCell>
                  <TableCell align="right">{personalDetails.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" align="right">
                    מייל
                  </TableCell>
                  <TableCell align="right">{personalDetails.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" align="right">
                    טלפון נייד
                  </TableCell>
                  <TableCell align="right">{personalDetails.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" align="right">
                    מס' נוסעים
                  </TableCell>
                  <TableCell align="right">
                    {personalDetails.numPassengers.toString() +
                      " (מתוכם " +
                      personalDetails.numMembers.toString() +
                      " מנויים)"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" align="right">
                    תחנת עלייה
                  </TableCell>
                  <TableCell align="right">
                    {personalDetails.boardingStation}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" align="right">
                    תחנת ירידה
                  </TableCell>
                  <TableCell align="right">
                    {personalDetails.alightingStation}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" align="right">
                    מחיר
                  </TableCell>
                  <TableCell align="right">
                    {personalDetails.price.toString() + "₪"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className={style.ConfirmCancel}>
            <button
              className={style.SubmitButton}
              onClick={() => setConfirmedDetails(true)}
            >
              אישור והמשך
            </button>
            <button className={style.SubmitButton} onClick={closeSubmit}>
              חזרה
            </button>
          </div>
        </div>
      )}
      {confirmedDetails && !registeredSuccessfully && !loading && (
        <div className={style.SubmitDialog}>
          <p className={style.DialogTitle}>שימו לב!</p>
          <ul className={style.AttentionDetails}>
            <li>
              אם אינכם מנויי הסעה וטרם שילמתם,{" "}
              <b>
                יש לשלם דרך{" "}
                <a
                  href={process.env.REACT_APP_PAYBOX_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style.RegisterText}
                >
                  פייבוקס
                </a>
              </b>
            </li>
            <li>
              מי שלא שילם, <b>מקומו לא יישמר!</b>
            </li>
            <li>ההסעה יוצאת בזמן - אין המתנה למאחרים</li>
            <li>ההסעה חזור יוצאת מחניית השחקנים בארנה, כ-10 דק' מתום המשחק</li>
            <li>לשאלות ולפרטים נוספים ניתן לפנות לניב - 0503511920</li>
          </ul>
          <div className={style.ConfirmCancel}>
            <button className={style.SubmitButton} onClick={registerToBus}>
              אישור והרשמה
            </button>
            <button className={style.SubmitButton} onClick={closeSubmit}>
              ביטול
            </button>
          </div>
        </div>
      )}
      {registeredSuccessfully && !loading && (
        <div className={style.SubmitDialog}>
          {tooManyPassengers ? (
            <p className={style.SuccessMessage}>
              אנו מצטערים, אך נגמרו המקומות להסעה זו
            </p>
          ) : (
            <p className={style.SuccessMessage}>
              נרשמתם בהצלחה!{" "}
              {personalDetails.sendMail &&
                `מייל עם הפרטים יישלח לכתובת ${personalDetails.email}`}
            </p>
          )}
          <Link to="/">
            <button className={style.SubmitButton}>למסך הבית</button>
          </Link>
        </div>
      )}
    </Dialog>
  );
}

export default SubmitDialog;
