import { Dialog, IconButton, InputAdornment, TextField } from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { app } from "../utils/firebase";
import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import style from "./SignIn.module.less";

const auth = getAuth(app);

function SignIn({
  signInDialogOpen,
  setSignInDialogOpen,
  goToSignUpDialog,
  goToResetPasswordDialog,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("הכניסו כתובת מייל תקינה")
      .required("אנא הכניסו כתובת מייל"),
    password: yup.string("אנא הכניסו סיסמה").required("אנא הכניסו סיסמה"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values, { setErrors }) => {
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(() => {
          // Signed in
          closeSignIn();
        })
        .catch(() => {
          setErrors({ password: "כתובת המייל או הסיסמה שגויים" });
        });
    },
  });

  const closeSignIn = useCallback(() => {
    setSignInDialogOpen(false);
    formik.handleReset(undefined);
  }, [setSignInDialogOpen]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter" && signInDialogOpen) {
        event.preventDefault();
        formik.handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [signInDialogOpen]);

  useEffect(() => {
    formik.handleReset(undefined);
  }, [signInDialogOpen]);

  return (
    <Dialog open={signInDialogOpen} onClose={closeSignIn}>
      <div className={style.signIn}>
        <p className={style.DialogTitle}>התחברות לאתר</p>
        <form className={style.SignInForm} onSubmit={formik.handleSubmit}>
          <TextField
            id="email"
            name="email"
            placeholder="מייל"
            margin="dense"
            autoComplete="off"
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            onChange={formik.handleChange}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            id="password"
            name="password"
            placeholder="סיסמה"
            margin="dense"
            autoComplete="off"
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            type={showPassword ? "text" : "password"}
            onChange={formik.handleChange}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <button className={style.EnterButton} type="submit">
            כניסה
          </button>
        </form>
        <div className={style.NoUserText}>
          <p>עדיין אין לכם משתמש?&nbsp; </p>
          <p className={style.RegisterText} onClick={goToSignUpDialog}>
            הירשמו
          </p>
        </div>
        <div className={style.NoUserText}>
          <p>שכחתם סיסמה?&nbsp; </p>
          <p className={style.RegisterText} onClick={goToResetPasswordDialog}>
            לאיפוס סיסמה
          </p>
        </div>
      </div>
    </Dialog>
  );
}

export default SignIn;
