import { useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Dialog } from "@mui/material";
import style from "./DeleteBusDialog.module.less";

function DeleteBusDialog({ open, setOpen, bus }) {
  const [deleteSuccessDialogOpen, setDeleteSuccessDialogOpen] = useState(false);

  const deleteBus = async (bus) => {
    await deleteDoc(doc(db, "Buses", bus.busID));
  };

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div className={style.SubmitDialog}>
          <p className={style.SignUpInfo}>
            האם אתם בטוחים שברצונכם למחוק את ההסעה? לא ניתן לבטל פעולה זו
          </p>
          <div className={style.ConfirmCancel}>
            <button
              className={style.SubmitButton}
              onClick={() => {
                deleteBus(bus).then(() => {
                  setOpen(false);
                  setDeleteSuccessDialogOpen(true);
                });
              }}
            >
              מחיקה
            </button>
            <button
              className={style.SubmitButton}
              onClick={() => {
                setOpen(false);
              }}
            >
              חזרה
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={deleteSuccessDialogOpen}
        onClose={() => setDeleteSuccessDialogOpen(false)}
      >
        <div className={style.SubmitDialog}>
          <p className={style.SuccessMessage}>מחיקה בוצעה בהצלחה! </p>
          <button
            className={style.SubmitButton}
            onClick={() => {
              setDeleteSuccessDialogOpen(false);
              window.location.reload();
            }}
          >
            למסך הבית
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default DeleteBusDialog;
