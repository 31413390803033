import { CircularProgress, Dialog } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { doc, updateDoc, deleteField, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Link } from "react-router-dom";
import { AuthenticatedUserContext } from "../utils/UserProvider";
import style from "./CancelDialog.module.less";

function CancelDialog({ cancelDialogOpen, setCancelDialogOpen, busDetails }) {
  const [confirmedCancel, setConfirmedCancel] = useState(false);
  const [canceledSuccessfully, setCanceledSuccessfully] = useState(false);
  const [loading, setLoading] = useState(true);
  const [personalDetails, setPersonalDetails] = useState(null);
  const { user } = useContext(AuthenticatedUserContext);

  const closeSubmit = () => {
    if (!canceledSuccessfully) {
      setCancelDialogOpen(false);
      setConfirmedCancel(false);
    }
  };
  const cancelRegistrationToBus = async (email) => {
    setConfirmedCancel(true);
    setLoading(true);
    const docSnap = await getDoc(doc(db, "Buses", busDetails.busID));
    const busData = docSnap.data();
    const previousDetails =
      busData.registered_users[email.replaceAll(".", "@")];
    const userId = email.replaceAll(".", "@");
    if (docSnap.exists() && previousDetails) {
      const registeredToGame = busData.registered_users_to_game;
      const registeredFromGame = busData.registered_users_from_game;

      if (previousDetails) {
        if (previousDetails.boardingStation !== "none") {
          delete registeredToGame[previousDetails.boardingStation][userId];
        }
        if (previousDetails.alightingStation !== "none") {
          delete registeredFromGame[previousDetails.alightingStation][userId];
        }
      }

      await updateDoc(doc(db, "Buses", busDetails.busID), {
        [`registered_users.${userId}`]: deleteField(),
        registered_users_to_game: registeredToGame,
        registered_users_from_game: registeredFromGame,
      });
      setCanceledSuccessfully(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user && !personalDetails) {
      getDoc(doc(db, "Buses", busDetails.busID)).then((docSnap) => {
        if (docSnap.exists()) {
          setPersonalDetails(
            docSnap.data().registered_users[user.email.replaceAll(".", "@")]
          );
        }
      });
    }
    setLoading(false);
  }, [busDetails.busID, personalDetails, user]);

  return (
    <Dialog open={cancelDialogOpen} onClose={closeSubmit}>
      {loading && (
        <div className={style.LoadingDialog}>
          <CircularProgress />
        </div>
      )}
      {Boolean(personalDetails) &&
        !confirmedCancel &&
        !canceledSuccessfully &&
        !loading && (
          <div className={style.SubmitDialog}>
            <div className={style.DialogTitle}>
              <p>ביטול רישום להסעה</p>
              <p>
                {busDetails.opponentName} -{" " + busDetails.gameDate}
              </p>
            </div>
            <p className={style.CancelInfoText}>
              לבטל רישום של {personalDetails.numPassengers} נוסעים להסעה על שם{" "}
              {personalDetails.name}?
            </p>
            <p className={style.CancelInfoText}>
              <b>שימו לב! </b>
              לחיצה על "אישור" תבטל סופית את הרשמתכם להסעה
            </p>
            <div className={style.ConfirmCancel}>
              <button
                className={style.SubmitButton}
                onClick={() => cancelRegistrationToBus(personalDetails.email)}
              >
                אישור
              </button>
              <button
                className={style.SubmitButton}
                onClick={() => {
                  if (user) {
                    closeSubmit();
                  } else {
                    setPersonalDetails(null);
                  }
                }}
              >
                חזרה
              </button>
            </div>
          </div>
        )}

      {Boolean(personalDetails) && canceledSuccessfully && !loading && (
        <div className={style.SubmitDialog}>
          <p className={style.SuccessMessage}>ביטול בוצע בהצלחה! </p>
          <Link to="/">
            <button className={style.SubmitButton}>למסך הבית</button>
          </Link>
        </div>
      )}
    </Dialog>
  );
}

export default CancelDialog;
