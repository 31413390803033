import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  CircularProgress,
  Checkbox,
  Popover,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import SubmitDialog from "./SubmitDialog";
import { useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import * as yup from "yup";
import { useFormik, yupToFormErrors } from "formik";
import { AuthenticatedUserContext } from "../utils/UserProvider";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import googleMaps from "../assets/google_maps.png";
import waze from "../assets/waze.png";
import CancelDialog from "./CancelDialog";
import style from "./BusRegister.module.less";
import { useStations } from "../utils/useStations";
import { calculateTotalUsersOneWay } from "../utils/utils";

function BusRegister() {
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [anchorSavePopover, setAnchorSavePopover] = useState(null);
  const [anchorPhonePopover, setAnchorPhonePopover] = useState(null);
  const [numPassengersArray, setNumPassengersArray] = useState([0]);
  const [numMembers, setNumMembers] = useState(0);
  const [price, setPrice] = useState(0);
  const { state } = useLocation();
  const { user } = useContext(AuthenticatedUserContext);
  const {
    busTime,
    gameTime,
    gameDate,
    opponentName,
    isUserRegistered,
    busID,
    openStations,
  } = state; // Read values passed on state
  const savePopoverOpen = Boolean(anchorSavePopover);
  const phonePopoverOpen = Boolean(anchorPhonePopover);
  const stations = useStations();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("הכניסו כתובת מייל תקינה")
      .required("אנא הכניסו כתובת מייל"),
    name: yup
      .string()
      .min(4, "אנא הכניסו שם מלא")
      .required("אנא הכניסו שם מלא"),
    phone: yup
      .string()
      .min(4, "אנא הכניסו מס' טלפון תקין")
      .max(20, "אנא הכניסו מס' טלפון תקין")
      .matches(/^\d+$/, "אנא הכניסו ספרות בלבד")
      .required("אנא הכניס טלפון נייד"),
    numPassengers: yup.string().required("אנא בחרו מס' נוסעים"),
  });

  const formik = useFormik({
    initialValues: {
      email: user ? user.email : "",
      name: user ? user.displayName : "",
      phone: "",
      numPassengers: "",
      boardingStation: openStations[0],
      alightingStation: openStations[0],
      sendMail: true,
      saveDetails: false,
    },
    validate: async (values) => {
      const errors = {};
      if (
        values.boardingStation === "none" &&
        values.alightingStation === "none"
      ) {
        errors.alightingStation =
          "אם אתם לא נוסעים באף אחד מהכיוונים, למה להירשם? (;";
      }
      try {
        await validationSchema.validate(values, { abortEarly: false });
      } catch (e) {
        return {
          ...yupToFormErrors(e),
          ...errors,
        };
      }
      return errors;
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      const isEmailOk = await checkEmail(values.email);
      if (isEmailOk) {
        setSubmitDialogOpen(true);
        if (values.saveDetails) {
          await setPreferences(values.email);
        }
      } else {
        setErrors({ email: "כתובת המייל הזו כבר רשומה להסעה" });
      }
      setSubmitting(false);
    },
  });

  const checkEmail = async (email) => {
    if (user) {
      return true;
    }
    const docSnap = await getDoc(doc(db, "Buses", busID));
    if (docSnap.exists()) {
      return (
        docSnap.data().registered_users[email.replaceAll(".", "@")] ===
        undefined
      );
    }
  };

  const setPreferences = async (email) => {
    await updateDoc(doc(db, "Users", email), {
      preferences: {
        name: formik.values.name,
        alightingStation: formik.values.alightingStation,
        boardingStation: formik.values.boardingStation,
        numPassengers: formik.values.numPassengers,
        phone: formik.values.phone,
        sendMail: formik.values.sendMail,
      },
    });
  };

  const getPreferences = async (email) => {
    const docSnap = await getDoc(doc(db, "Users", email));
    if (docSnap.exists()) {
      return docSnap.data().preferences;
    }
    return null;
  };

  const calculatePrice = (
    numPassengers,
    numMembers,
    boardingStation,
    alightingStation
  ) => {
    const numPaying = Math.max(numPassengers - numMembers, 0);
    const toGamePrice =
      stations.find((station) => station.name === boardingStation)?.price || 0;
    const fromGamePrice =
      stations.find((station) => station.name === alightingStation)?.price || 0;
    setPrice(numPaying * (toGamePrice + fromGamePrice));
  };

  const getNumMembers = async (email) => {
    const docSnap = await getDoc(doc(db, "Members", email));
    if (docSnap.exists()) {
      return docSnap.data().numMembers;
    } else return 0;
  };

  const handlePopoverOpen = (event, setAnchorElementPopover) => {
    setAnchorElementPopover(event.currentTarget);
  };

  const handlePopoverClose = (setAnchorElementPopover) => {
    setAnchorElementPopover(null);
  };

  const togglePopover = (
    event,
    anchorElementPopover,
    setAnchorElementPopover
  ) => {
    if (anchorElementPopover) {
      setAnchorElementPopover(null);
    } else {
      setAnchorElementPopover(event.currentTarget);
    }
  };

  useEffect(() => {
    const getPreviousDetails = async (email) => {
      const docSnap = await getDoc(doc(db, "Buses", busID));
      if (docSnap.exists()) {
        return docSnap.data().registered_users[email.replaceAll(".", "@")];
      }
    };
    const checkAvailablePlaces = async (email) => {
      const docSnap = await getDoc(doc(db, "Buses", busID));
      if (docSnap.exists()) {
        const busData = docSnap.data();
        const maxPassengers = busData.max_passengers;
        const totalPassengersToGame = calculateTotalUsersOneWay(
          busData.registered_users_to_game,
          busData.open_stations
        );
        const totalPassengersFromGame = calculateTotalUsersOneWay(
          busData.registered_users_from_game,
          busData.open_stations
        );

        let details = await getPreviousDetails(email);
        const prevPassengers = details ? details.numPassengers : 0;
        const availablePlacesToGame = Math.max(
          0,
          maxPassengers - totalPassengersToGame + prevPassengers
        );
        const availablePlacesFromGame = Math.max(
          0,
          maxPassengers - totalPassengersFromGame + prevPassengers
        );

        return (
          Math.min(
            Math.max(availablePlacesFromGame, availablePlacesToGame),
            10
          ) + 1
        );
      }
    };
    const getDetails = async (email) => {
      let details = await getPreviousDetails(email);
      if (!details) {
        details = await getPreferences(email);
      }
      return details;
    };

    if (user) {
      getDetails(user.email).then((details) => {
        if (details) {
          checkAvailablePlaces(user.email).then((arrayLen) => {
            formik.setValues({
              alightingStation:
                openStations.includes(details.alightingStation) ||
                details.alightingStation === "none"
                  ? details.alightingStation
                  : formik.values.alightingStation,
              boardingStation:
                openStations.includes(details.boardingStation) ||
                details.alightingStation === "none"
                  ? details.boardingStation
                  : formik.values.boardingStation,
              sendMail:
                details.sendMail !== undefined
                  ? details.sendMail
                  : formik.values.sendMail,
              phone:
                details.phone !== undefined
                  ? details.phone
                  : formik.values.phone,
              numPassengers:
                details.numPassengers !== undefined
                  ? Math.min(details.numPassengers, arrayLen - 1)
                  : formik.values.numPassengers,
              saveDetails: formik.values.saveDetails,
              name:
                details.name !== undefined ? details.name : user.displayName,
              email: user.email,
            });
          });
        }
      });
      if (formik.values.numPassengers === "") {
        checkAvailablePlaces(user.email).then((arrayLen) => {
          setNumPassengersArray([...Array(arrayLen).keys()].slice(1));
        });
      }
      getNumMembers(user.email).then((num) => {
        if (numMembers !== num) {
          setNumMembers(num);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    calculatePrice(
      formik.values.numPassengers,
      numMembers,
      formik.values.boardingStation,
      formik.values.alightingStation
    );
  }, [
    numMembers,
    formik.values.numPassengers,
    formik.values.boardingStation,
    formik.values.alightingStation,
  ]);

  const priceRange = useMemo(() => {
    const prices = stations
      .filter((station) => openStations.includes(station.name))
      .map((station) => station.price);
    return { min: Math.min(...prices), max: Math.max(...prices) };
  }, [stations, openStations]);

  return (
    <div className={style.BusRegister}>
      <div className={style.FormTitle}>
        <p>הרשמה להסעה</p>
        <p>
          {opponentName} -{" " + gameDate}
        </p>
      </div>
      <div className={style.InfoCard}>
        <div>
          <div className={style.InfoCardTitle}>
            <h3>שעת המשחק: {gameTime}</h3>
            {Number.isFinite(priceRange.min) && (
              <h3>
                {priceRange.min !== priceRange.max &&
                  `₪${priceRange.max} - ₪${priceRange.min} `}
                {priceRange.min === priceRange.max && `₪${priceRange.min} `}
                לכיוון
              </h3>
            )}
          </div>
          <div className={style.InfoCardTitle}>
            <div className={style.NoUserText}>
              <p style={{ margin: 0 }}>תשלום דרך&nbsp;</p>
              <a
                href={process.env.REACT_APP_PAYBOX_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className={style.RegisterText}
              >
                פייבוקס
              </a>
            </div>
            {(!user || isUserRegistered) && (
              <button
                className={style.CancelRegistrationButton}
                onClick={() => setCancelDialogOpen(true)}
              >
                ביטול רישום
              </button>
            )}
          </div>
          {stations
            .filter((station) => openStations.includes(station.name))
            .map((station) => (
              <div key={station.name} className={style.StationInfo}>
                <p>
                  {station.name}: {busTime[station.name]}
                </p>

                <div className={style.NavigationLogoLinks}>
                  <a
                    href={station.googleMaps}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={googleMaps}
                      alt={""}
                      className={style.NavigationLogo}
                    />
                  </a>
                  <a
                    href={station.waze}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={waze} alt={""} className={style.NavigationLogo} />
                  </a>
                </div>
              </div>
            ))}

          <div className={style.StationInfo}>
            <p>איסוף מחניית השחקנים: 10 דק' מתום המשחק</p>

            <div className={style.NavigationLogoLinks}>
              <a
                href={"https://goo.gl/maps/SmQ2Lhx7aAcRqUmWA"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleMaps}
                  alt={""}
                  className={style.NavigationLogo}
                />
              </a>
              <a
                href={"https://waze.com/ul/hsv9h8u504"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={waze} alt={""} className={style.NavigationLogo} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <form
        className={style.BusForm}
        dir="rtl"
        lang="he"
        onSubmit={formik.handleSubmit}
      >
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            תחנת עלייה (בהלוך)
          </FormLabel>
          <RadioGroup
            id={"boardingStation"}
            name={"boardingStation"}
            aria-labelledby="demo-radio-buttons-group-label"
            style={{ marginTop: "1vh", marginBottom: "1vh" }}
            value={formik.values.boardingStation}
            onChange={formik.handleChange}
          >
            {stations
              .filter((station) => openStations.includes(station.name))
              .map((station) => (
                <FormControlLabel
                  key={station.name}
                  value={station.name}
                  control={<Radio />}
                  label={`${station.name} (₪${station.price})`}
                />
              ))}
            <FormControlLabel
              value="none"
              control={<Radio />}
              label="אני נוסע/ת רק חזור"
            />
          </RadioGroup>
          <FormLabel id="alighting-station-radio-buttons-group">
            תחנת ירידה (בחזור)
          </FormLabel>
          <RadioGroup
            id={"alightingStation"}
            name={"alightingStation"}
            aria-labelledby="alighting-station-radio-buttons-group"
            style={{ marginTop: "1vh", marginBottom: "1vh" }}
            value={formik.values.alightingStation}
            onChange={formik.handleChange}
          >
            {stations
              .filter((station) => openStations.includes(station.name))
              .map((station) => (
                <FormControlLabel
                  key={station.name}
                  value={station.name}
                  control={<Radio />}
                  label={`${station.name} (₪${station.price})`}
                />
              ))}
            <FormControlLabel
              value="none"
              control={<Radio />}
              label="אני נוסע/ת רק הלוך"
            />
          </RadioGroup>
          <FormLabel id="demo-radio-buttons-group-label">מס' נוסעים</FormLabel>
          <div className={style.NumPassengers}>
            <Select
              id="numPassengers"
              name={"numPassengers"}
              value={formik.values.numPassengers}
              label=""
              displayEmpty
              error={
                formik.touched.numPassengers &&
                Boolean(formik.errors.numPassengers)
              }
              onChange={formik.handleChange}
            >
              <MenuItem value={""}>מס' נוסעים</MenuItem>
              {numPassengersArray.map((val) => {
                return (
                  <MenuItem value={val} key={val}>
                    {val}
                  </MenuItem>
                );
              })}
            </Select>
            {formik.touched.numPassengers &&
              Boolean(formik.errors.numPassengers) && (
                <FormHelperText error={true}>
                  {formik.touched.numPassengers && formik.errors.numPassengers}
                </FormHelperText>
              )}
          </div>
          <FormLabel id="demo-radio-buttons-group-label">שם מלא </FormLabel>
          <TextField
            id="name"
            name="name"
            margin="dense"
            autoComplete="off"
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            onChange={formik.handleChange}
            helperText={formik.touched.name && formik.errors.name}
          />
          <FormLabel id="demo-radio-buttons-group-label">מייל </FormLabel>
          <TextField
            id="email"
            name="email"
            placeholder={user ? user.email : "מייל"}
            margin="dense"
            autoComplete="off"
            disabled={user ? user.email !== undefined : false}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            onChange={formik.handleChange}
            helperText={formik.touched.email && formik.errors.email}
          />
          <FormLabel className={style.PhoneLabel}>
            טלפון נייד{" "}
            <Popover
              sx={{
                pointerEvents: "none",
              }}
              open={phonePopoverOpen}
              anchorEl={anchorPhonePopover}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              onClose={() => handlePopoverClose(setAnchorPhonePopover)}
              disableRestoreFocus
            >
              <Typography style={{ padding: "2px 5px" }}>
                {" "}
                אנו נשתמש בטלפון זה רק
              </Typography>
              <Typography style={{ padding: "2px 5px" }}>
                לצורך תיאום ההגעה להסעה
              </Typography>
            </Popover>
            <HelpOutlineIcon
              color={"inherit"}
              fontSize={"medium"}
              className={style.PhoneInfoIcon}
              onMouseEnter={(event) =>
                handlePopoverOpen(event, setAnchorPhonePopover)
              }
              onMouseLeave={() => handlePopoverClose(setAnchorPhonePopover)}
              onClick={(event) =>
                togglePopover(event, anchorPhonePopover, setAnchorPhonePopover)
              }
            />
          </FormLabel>
          <TextField
            id="phone"
            name="phone"
            placeholder="טלפון נייד"
            margin="dense"
            autoComplete="off"
            value={formik.values.phone}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            onChange={formik.handleChange}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </FormControl>
        {numMembers > 0 && (
          <div className={style.NumMembers}>
            <FormLabel
              style={{
                fontSize: "1.25em",
                marginLeft: 10,
                color: "black",
              }}
            >
              מס' מנויים:{" "}
            </FormLabel>
            <FormLabel
              style={{
                fontSize: "1.25em",
                marginLeft: 10,
                color: "black",
              }}
            >
              {numMembers}
            </FormLabel>
          </div>
        )}
        <div className={style.NumMembers}>
          <FormLabel
            style={{
              fontSize: "1.25em",
              marginLeft: 10,
              color: "black",
            }}
          >
            מחיר:{" "}
          </FormLabel>
          <FormLabel
            style={{
              fontSize: "1.25em",
              marginLeft: 10,
              color: "black",
            }}
          >
            ₪{price}
          </FormLabel>
        </div>
        {formik.touched.alightingStation &&
          Boolean(formik.errors.alightingStation) && (
            <FormHelperText error={true}>
              {formik.touched.alightingStation &&
                formik.errors.alightingStation}
            </FormHelperText>
          )}
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              id={"sendMail"}
              name={"sendMail"}
              value={formik.values.sendMail}
              onChange={formik.handleChange}
            />
          }
          label="שלחו לי מייל אישור עם פרטי ההסעה"
        />
        <div className={style.Checkbox}>
          <FormControlLabel
            control={
              <Checkbox
                id={"saveDetails"}
                name={"saveDetails"}
                disabled={!user}
                value={formik.values.saveDetails}
                onChange={formik.handleChange}
              />
            }
            label="שמירת פרטים להסעות הבאות"
          />
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={savePopoverOpen}
            anchorEl={anchorSavePopover}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            onClose={() => handlePopoverClose(setAnchorSavePopover)}
            disableRestoreFocus
          >
            <Typography style={{ padding: "2px 5px" }}>
              {" "}
              שמירת כל פרטי הרישום (מס' נוסעים, תחנות, שם וכו') כך שלא תצטרכו
              להכניסם שוב בפעם הבאה.{" "}
            </Typography>
            <Typography style={{ padding: "2px 5px" }}>
              ניתן לערוך את הפרטים בכל עת דרך ההגדרות.{" "}
            </Typography>
          </Popover>
          <HelpOutlineIcon
            color={"inherit"}
            fontSize={"medium"}
            className={style.CheckboxInfoIcon}
            onMouseEnter={(event) =>
              handlePopoverOpen(event, setAnchorSavePopover)
            }
            onMouseLeave={() => handlePopoverClose(setAnchorSavePopover)}
            onClick={(event) =>
              togglePopover(event, anchorSavePopover, setAnchorSavePopover)
            }
          />
        </div>
        <button className={style.SubmitButton} type={"submit"}>
          {formik.isSubmitting ? (
            <CircularProgress size={"1em"} color={"info"} />
          ) : (
            "המשך"
          )}
        </button>
      </form>
      <SubmitDialog
        setSubmitDialogOpen={setSubmitDialogOpen}
        submitDialogOpen={submitDialogOpen}
        personalDetails={{
          name: formik.values.name,
          alightingStation: formik.values.alightingStation,
          boardingStation: formik.values.boardingStation,
          numPassengers: formik.values.numPassengers,
          email: formik.values.email,
          phone: formik.values.phone,
          numMembers: Math.min(numMembers, formik.values.numPassengers),
          sendMail: formik.values.sendMail,
          sentMail: false,
          price: price,
        }}
        busDetails={state}
      />
      <CancelDialog
        cancelDialogOpen={cancelDialogOpen}
        setCancelDialogOpen={setCancelDialogOpen}
        busDetails={state}
      />
    </div>
  );
}

export default BusRegister;
