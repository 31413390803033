import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  query,
  where,
  orderBy,
  collection,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import {
  timeToString,
  convertNumToDayOfWeek,
  getLogoURL,
  calculateTotalUsersOneWay,
} from "../utils/utils";
import { db } from "../utils/firebase";
import { AuthenticatedUserContext } from "../utils/UserProvider";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import style from "./BusHistory.module.less";
import { useStations } from "../utils/useStations";

function BusHistory() {
  const { user } = useContext(AuthenticatedUserContext);
  const [buses, setBuses] = useState([]);
  const navigate = useNavigate();
  const [displayAsAdmin, setDisplayAsAdmin] = useState(false);
  const [loadingBuses, setLoadingBuses] = useState(true);
  const stations = useStations();

  // load buses
  useEffect(() => {
    const loadBuses = async () => {
      const nowDate = Timestamp.now().toDate();
      nowDate.setHours(0, 0, 0, 0);
      const onlyOldBusesQuery = query(
        collection(db, "Buses"),
        where("date", "<", Timestamp.fromDate(nowDate)),
        where("date", ">", Timestamp.fromMillis(Date.parse("2023-09-01"))),
        orderBy("date")
      );
      const querySnapshot = await getDocs(onlyOldBusesQuery);

      const newBuses = [];
      for (const busDoc of querySnapshot.docs) {
        const busData = busDoc.data();
        newBuses.push({
          opponent: busData.opponent,
          opponentName: busData.opponent.name,
          opponentLogo: await getLogoURL(busData.opponent.name),
          maxPassengers: busData.max_passengers,
          gameDate:
            busData.date.toDate().getDate() +
            "/" +
            (parseInt(busData.date.toDate().getMonth()) + 1).toString() +
            "/" +
            busData.date.toDate().getFullYear().toString().slice(-2),
          gameTime: timeToString(busData.game_time),
          gameDay: busData.date.toDate().getDay(),
          busTime: Object.fromEntries(
            stations
              .filter((station) => busData.open_stations.includes(station.name))
              .map((station) => [
                station.name,
                timeToString(
                  dayjs(busData.bus_time.toDate()).add(station.timeToAdd, "m")
                ),
              ])
          ),
          busID: busDoc.id,
          isBusFull:
            calculateTotalUsersOneWay(
              busData.registered_users_from_game,
              busData.open_stations
            ) >= busData.max_passengers ||
            calculateTotalUsersOneWay(
              busData.registered_users_to_game,
              busData.open_stations
            ) >= busData.max_passengers,
          registeredUsers: busData.registered_users,
          registeredUsersToGame: busData.registered_users_to_game,
          registeredUsersFromGame: busData.registered_users_from_game,
          openStations: busData.open_stations,
        });
      }

      setBuses(newBuses);
      setLoadingBuses(false);
    };
    if (stations.length) loadBuses();
  }, [user, stations]);

  // track user changes
  useEffect(() => {
    if (user) {
      setDisplayAsAdmin(user.displayAsAdmin);
    }
  }, [user]);
  if (displayAsAdmin) {
    return (
      <div className={style.Home}>
        {loadingBuses && <CircularProgress className={style.LoadingBuses} />}
        {!loadingBuses && (
          <span className={style.ContentSubtitle}>היסטוריית הסעות:</span>
        )}
        {buses.length === 0 && !loadingBuses && (
          <div className={style.noBusesContainer}>
            <p>אין הסעות קודמות</p>
          </div>
        )}
        {buses.length > 0 &&
          buses.map((bus, i) => {
            return (
              <div className={style.GameCard} key={i}>
                <div className={style.LogoInfo}>
                  <img
                    className={style.OpponentLogo}
                    src={bus.opponentLogo}
                    alt="opponent logo"
                  />

                  <div className={style.GameInfo}>
                    <h3 className={style.OpponentName}>{bus.opponentName}</h3>
                    <p>
                      {convertNumToDayOfWeek(bus.gameDay)} {bus.gameDate}
                    </p>
                    <p>משחק: {bus.gameTime}</p>
                    <p>הסעה: {bus.busTime[bus.openStations[0]]}</p>
                    <p>פיס ארנה</p>
                    <p>מקס' נוסעים: {bus.maxPassengers}</p>
                  </div>
                </div>

                <button
                  className={style.RegisterButton}
                  onClick={() => {
                    navigate("/registered_users", { state: bus });
                  }}
                >
                  משתמשים רשומים
                </button>
              </div>
            );
          })}
      </div>
    );
  } else {
    return <div />;
  }
}
export default BusHistory;
