import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  query,
  where,
  orderBy,
  collection,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import {
  timeToString,
  convertNumToDayOfWeek,
  getLogoURL,
  calculateTotalUsersOneWay,
} from "../utils/utils";
import DeleteBusDialog from "./DeleteBusDialog";
import { db } from "../utils/firebase";
import { AuthenticatedUserContext } from "../utils/UserProvider";
import dayjs from "dayjs";
import googleMaps from "../assets/google_maps.png";
import waze from "../assets/waze.png";
import facebook from "../assets/facebook.png";
import whatsapp from "../assets/whatsapp.png";
import hapoel from "../assets/hapoel.png";
import logo from "../assets/mehutz_lahomot_logo.png"
import { CircularProgress, Dialog, Menu, MenuItem } from "@mui/material";
import style from "./Home.module.less";
import { useStations } from "../utils/useStations";
import { History, MoreVert } from "@mui/icons-material";

const TICKETS_LINK = "https://tickets.hapoel.co.il/"

function Home({ setSignInDialogOpen }) {
  const [notSignedDialogOpen, setNotSignedDialogOpen] = useState(false);
  const { user } = useContext(AuthenticatedUserContext);
  const [buses, setBuses] = useState([]);
  const navigate = useNavigate();
  const [displayAsAdmin, setDisplayAsAdmin] = useState(false);
  const [deleteBusDialogOpen, setDeleteBusDialogOpen] = useState(false);
  const [loadingBuses, setLoadingBuses] = useState(true);
  const [busToDelete, setBusToDelete] = useState(null);
  const [busOptionsAnchor, setBusOptionsAnchor] = useState(null);
  const [selectedBus, setSelectedBus] = useState(null);
  const stations = useStations();

  const isUserRegistered = (registeredByStations, user) => {
    if (!user) return false;
    return Object.entries(registeredByStations).some(
      // eslint-disable-next-line no-unused-vars
      ([_, users]) => users[user.email.replaceAll(".", "@")]
    );
  };

  // load buses
  useEffect(() => {
    const loadBuses = async () => {
      const nowDate = Timestamp.now().toDate();
      nowDate.setHours(0, 0, 0, 0);
      const onlyFutureBusesQuery = query(
        collection(db, "Buses"),
        where("date", ">", Timestamp.fromDate(nowDate)),
        orderBy("date")
      );
      const querySnapshot = await getDocs(onlyFutureBusesQuery);

      const newBuses = [];
      for (const busDoc of querySnapshot.docs) {
        const busData = busDoc.data();
        newBuses.push({
          opponent: busData.opponent,
          opponentName: busData.opponent.name,
          opponentLogo: await getLogoURL(busData.opponent.name),
          maxPassengers: busData.max_passengers,
          gameDate:
            busData.date.toDate().getDate() +
            "/" +
            (parseInt(busData.date.toDate().getMonth()) + 1).toString(),
          gameTime: timeToString(busData.game_time),
          gameDay: busData.date.toDate().getDay(),
          busTime: Object.fromEntries(
            stations
              .filter((station) => busData.open_stations.includes(station.name))
              .map((station) => [
                station.name,
                timeToString(
                  dayjs(busData.bus_time.toDate()).add(station.timeToAdd, "m")
                ),
              ])
          ),
          busID: busDoc.id,
          isBusFull:
            calculateTotalUsersOneWay(
              busData.registered_users_from_game,
              busData.open_stations
            ) >= busData.max_passengers ||
            calculateTotalUsersOneWay(
              busData.registered_users_to_game,
              busData.open_stations
            ) >= busData.max_passengers,
          isUserRegistered:
            (user &&
              isUserRegistered(busData.registered_users_to_game, user)) ||
            isUserRegistered(busData.registered_users_from_game, user),
          fullBusTime: busData.bus_time.toDate(),
          fullGameTime: busData.game_time.toDate(),
          fullDate: busData.date.toDate(),
          registeredUsers: busData.registered_users,
          registeredUsersToGame: busData.registered_users_to_game,
          registeredUsersFromGame: busData.registered_users_from_game,
          openStations: busData.open_stations,
        });
      }

      setBuses(newBuses);
      setLoadingBuses(false);
    };
    if (stations.length) loadBuses();
  }, [user, stations]);

  // track user changes
  useEffect(() => {
    if (user) {
      setDisplayAsAdmin(user.displayAsAdmin);
    }
  }, [user]);

  const closeBusOptions = () => {
    setBusOptionsAnchor(null);
    setSelectedBus(null);
  };

  return (
    <div className={style.Home}>
      {loadingBuses && <CircularProgress className={style.LoadingBuses} />}
      {!loadingBuses && (
        <div className={style.FirstRow}>
          {buses.length > 0 && <span className={style.ContentSubtitle}>הסעות פתוחות:</span>}
          {displayAsAdmin && (
            <Link
              to="/bus_history"
              style={{
                textDecoration: "none",
                marginRight: "auto",
                marginLeft: 15,
                marginTop: 15,
              }}
            >
              <History fontSize={"large"} color={"primary"} />
            </Link>
          )}
        </div>
      )}
      {buses.length === 0 && !loadingBuses && (
        <div className={style.noBusesContainer}>
          <h2>ברוכים הבאים לאתר ההסעות של מחוץ לחומות!</h2>
          <a
            href={TICKETS_LINK}
            target="_blank"
            rel="noopener noreferrer"
            style={{fontSize: 20}}
          >
            לרכישת מנוי הסעות לחצו כאן
          </a>
          <p>כשייפתחו הסעות חדשות, הן יופיעו ממש פה</p>
          <p>בינתיים, אפשר להירשם לאתר כדי לקצר את התהליך ברגע שתיפתח הסעה</p>
          <p>
            ותמיד אפשר להתעדכן על פתיחת הסעות חדשות ב
            <a href="https://chat.whatsapp.com/Jl6sAaAGbTE4MYUc6nCvgy">
              קבוצת הוואטסאפ
            </a>{" "}
            שלנו
          </p>
          <p>יאללה הפועל!</p>
          <div className={style.logosContainer}>
            <img
              src={hapoel}
              alt={"hapoel logo"}
              className={style.logo}
            />
            <img
              src={logo}
              alt={"mehutz lahomot logo"}
              className={style.logo}
            />
          </div>
        </div>
      )}
      {buses.length > 0 &&
        buses.map((bus, i) => {
          return (
            <div className={style.GameCard} key={i}>
              <div className={style.LogoInfo}>
                <img
                  className={style.OpponentLogo}
                  src={bus.opponentLogo}
                  alt="opponent logo"
                />

                <div className={style.GameInfo}>
                  <h3 className={style.OpponentName}>{bus.opponentName}</h3>
                  <p>
                    {convertNumToDayOfWeek(bus.gameDay)} {bus.gameDate}
                  </p>
                  <p>משחק: {bus.gameTime}</p>
                  <p>הסעה: {bus.busTime[bus.openStations[0]]}</p>
                  <p>פיס ארנה</p>
                  {displayAsAdmin && <p>מקס' נוסעים: {bus.maxPassengers}</p>}
                  <a
                    href={TICKETS_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={style.TicketsLink}
                  >
                    כרטיסים למשחק
                  </a>
                </div>
                {displayAsAdmin && (
                  <div
                    className={style.BusOptions}
                    onClick={(e) => {
                      setBusOptionsAnchor(e.currentTarget);
                      setSelectedBus(bus);
                    }}
                  >
                    <MoreVert />
                  </div>
                )}
              </div>
              <button
                className={style.RegisterButton}
                disabled={bus.isBusFull && !bus.isUserRegistered}
                onClick={() => {
                  if (user) {
                    navigate("/bus", { state: bus });
                  } else {
                    setNotSignedDialogOpen(true);
                  }
                }}
              >
                {" "}
                {bus.isUserRegistered
                  ? "עריכת רישום"
                  : bus.isBusFull
                    ? "ההסעה מלאה"
                    : "לפרטים והרשמה"}
              </button>
            </div>
          );
        })}

      {displayAsAdmin && (
        <>
          <DeleteBusDialog
            open={deleteBusDialogOpen}
            setOpen={setDeleteBusDialogOpen}
            bus={busToDelete}
          />
          <Menu
            anchorEl={busOptionsAnchor}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(busOptionsAnchor)}
            onClose={closeBusOptions}
          >
            <MenuItem
              onClick={() => {
                closeBusOptions();
                navigate("/registered_users", { state: selectedBus });
              }}
            >
              משתמשים רשומים
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeBusOptions();
                navigate("/add_bus", { state: selectedBus });
              }}
            >
              עריכת הסעה
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeBusOptions();
                setBusToDelete(selectedBus);
                setDeleteBusDialogOpen(true);
              }}
            >
              מחיקת הסעה
            </MenuItem>
          </Menu>
        </>
      )}

      {displayAsAdmin && (
        <Link className={style.RegisterButtonLink} to="add_bus">
          <button className={style.RegisterButton}>הוספת הסעה</button>
        </Link>
      )}

      <p className={style.ContentSubtitle}>מחירון:</p>
      <div className={style.GameCard}>
        <ul className={style.PriceList}>
          <li className={style.row}>כיוון אחד - ₪20</li>
          <li className={style.row}>כיוון אחד (מחלף לטרון/צומת שילת) - ₪15</li>
          <li className={style.row}>מנוי שנתי - ₪350</li>
        </ul>
        <span className={style.MemberInfo}>{"לרכישת מנוי יש להתחבר לאזור האישי "}
          <a
            href={TICKETS_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className={style.MemberLink}
          >
            באתר הכרטיסים
          </a>
          {' וללחוץ על "הסעות"'}
        </span>
      </div>

      <p className={style.ContentSubtitle}>נקודות איסוף:</p>
      <div className={style.GameCard}>
        <ul>
          {stations.map((station) => (
            <li key={station.name}>
              <div className={style.StationInfo}>
                <p>{station.name}</p>
                <div className={style.NavigationLogoLinks}>
                  {station.googleMaps && (
                    <a
                      href={station.googleMaps}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={googleMaps}
                        alt={""}
                        className={style.NavigationLogo}
                      />
                    </a>
                  )}
                  {station.waze && (
                    <a
                      href={station.waze}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={waze}
                        alt={""}
                        className={style.NavigationLogo}
                      />
                    </a>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <a
        className={style.FacebookButton}
        href={"https://chat.whatsapp.com/Jl6sAaAGbTE4MYUc6nCvgy"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={whatsapp}
          alt={"facebook logo"}
          width={30}
          height={30}
          style={{ marginLeft: 10 }}
        />
        <div>לקבוצת הוואטסאפ</div>
      </a>
      <a
        className={style.FacebookButton}
        href={"https://www.facebook.com/groups/146607822184153"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={facebook}
          alt={"facebook logo"}
          width={30}
          height={30}
          style={{ marginLeft: 10 }}
        />
        <div>לקבוצת הפייסבוק</div>
      </a>
      <a
        className={style.FacebookButton}
        href={"https://www.facebook.com/mehutz.la"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={facebook}
          alt={"facebook logo"}
          width={30}
          height={30}
          style={{ marginLeft: 10 }}
        />
        <div>לדף הפייסבוק</div>
      </a>
      <Dialog
        open={notSignedDialogOpen}
        onClose={() => setNotSignedDialogOpen(false)}
      >
        <div className={style.NotSignedDialog}>
          <p className={style.SignUpInfo}>
            על מנת להירשם להסעה, צריך להיות מחוברים לאתר קודם
          </p>
          <button
            className={style.RegisterButton}
            onClick={() => {
              setNotSignedDialogOpen(false);
              setSignInDialogOpen(true);
            }}
          >
            להתחברות
          </button>
        </div>
      </Dialog>
    </div>
  );
}
export default Home;
