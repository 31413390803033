import {
  FormControl,
  TextField,
  FormHelperText,
  CircularProgress,
  Dialog,
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useLocation } from "react-router-dom";
import { getLogoURL } from "../utils/utils";
import style from "./AddBus.module.less";
import { useStations } from "../utils/useStations";

function AddBus() {
  const { state } = useLocation();
  const prevBus = state;
  const [gameDate, setGameDate] = useState(
    prevBus ? dayjs(prevBus.fullDate) : dayjs()
  );
  const [gameTime, setGameTime] = useState(
    prevBus ? dayjs(prevBus.fullGameTime) : dayjs()
  );
  const [busTime, setBusTime] = useState(
    prevBus ? dayjs(prevBus.fullBusTime) : dayjs()
  );
  const [uploadedBus, setUploadedBus] = useState(false);
  const [opponents, setOpponents] = useState([]);
  const stations = useStations();
  const [openStations, setOpenStations] = useState({});

  useEffect(() => {
    if (prevBus?.openStations) {
      const prevOpenStations = new Set(prevBus.openStations);
      setOpenStations(
        Object.fromEntries(
          stations.map((station) => [
            station.name,
            prevOpenStations.has(station.name),
          ])
        )
      );
    } else {
      setOpenStations(
        Object.fromEntries(
          stations.map((station) => [station.name, station.defaultOpen])
        )
      );
    }
  }, [stations, prevBus]);

  const onStationSwitch = (event) => {
    setOpenStations({
      ...openStations,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    const loadTeams = async () => {
      const snapshot = await getDocs(collection(db, "Teams"));

      const newOpponents = [];
      for (const teamDoc of snapshot.docs) {
        newOpponents.push({
          name: teamDoc.id,
          logo: await getLogoURL(teamDoc.id),
        });
      }
      setOpponents(newOpponents);
    };

    loadTeams();
  }, []);

  const validationSchema = yup.object({
    opponent: yup.object().required("אנא בחר יריבה").nullable(),
    maxPassengers: yup
      .number()
      .min(1, "אנא הכנס מספר גדול מ-0")
      .required("אנא בחר מס' נוסעים מקסימלי"),
  });
  const formik = useFormik({
    initialValues: {
      opponent: prevBus ? prevBus.opponent : {},
      maxPassengers: prevBus ? prevBus.maxPassengers : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (prevBus) {
        editBusInDB({
          date: gameDate.toDate(),
          game_time: gameTime.toDate(),
          bus_time: busTime.toDate(),
          opponent: formik.values.opponent,
          max_passengers: formik.values.maxPassengers,
          open_stations: Object.entries(openStations)
            .filter((station) => station[1])
            .map((station) => station[0]),
        });
      } else {
        addBusToDB({
          date: gameDate.toDate(),
          game_time: gameTime.toDate(),
          bus_time: busTime.toDate(),
          opponent: formik.values.opponent,
          max_passengers: formik.values.maxPassengers,
          open_stations: Object.entries(openStations)
            .filter((station) => station[1])
            .map((station) => station[0]),
          registered_users_to_game: Object.fromEntries(
            stations.map((station) => [station.name, {}])
          ),
          registered_users_from_game: Object.fromEntries(
            stations.map((station) => [station.name, {}])
          ),
          registered_users: {},
        });
      }
      actions.setSubmitting(false);
      setUploadedBus(true);
    },
  });
  const addBusToDB = (busInfo) => {
    addDoc(collection(db, "Buses"), busInfo);
  };

  const editBusInDB = (busInfo) => {
    updateDoc(doc(db, "Buses", prevBus.busID), busInfo);
  };

  return (
    <div className={style.BusRegister}>
      {formik.isSubmitting && (
        <Dialog open={true}>
          {" "}
          <div className={style.LoadingDialog}>
            <CircularProgress />
          </div>
        </Dialog>
      )}
      {!formik.isSubmitting && uploadedBus && (
        <Dialog open={true}>
          <div className={style.SubmitDialog}>
            <p className={style.SuccessMessage}>ההסעה נוספה בהצלחה!</p>
            <Link to="/">
              <button className={style.SubmitButton}>למסך הבית</button>
            </Link>
          </div>
        </Dialog>
      )}
      {
        <div className={style.BusRegister}>
          <div className={style.FormTitle}>
            <p>{prevBus ? "עריכת הסעה" : "יצירת הסעה"}</p>
          </div>
          <form
            className={style.BusForm}
            dir="rtl"
            lang="he"
            onSubmit={formik.handleSubmit}
          >
            <FormControl>
              <div className={style.ChooseOpponent}>
                <Autocomplete
                  id={"opponentName"}
                  name={"opponentName"}
                  loading={opponents.length === 0}
                  loadingText={"טוען..."}
                  onChange={(e, value) =>
                    formik.setFieldValue("opponent", value)
                  }
                  value={formik.values.opponent}
                  renderInput={(params) => {
                    return <TextField {...params} placeholder="בחר יריבה" />;
                  }}
                  options={opponents}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, opponent) => (
                    <Box {...props}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <img
                          src={opponent.logo}
                          alt={opponent.name}
                          loading={"lazy"}
                          width={50}
                          height={50}
                          style={{ marginLeft: "2vw" }}
                        />
                        <p>{opponent.name}</p>
                      </div>
                    </Box>
                  )}
                />
                {formik.touched.opponent && Boolean(formik.errors.opponent) && (
                  <FormHelperText error={true}>
                    {formik.touched.opponent && formik.errors.opponent}
                  </FormHelperText>
                )}
              </div>
              <div className={style.AddBusInput}>
                <p>תאריך המשחק</p>
                <DesktopDatePicker
                  label=""
                  inputFormat="DD/MM/YYYY"
                  value={gameDate}
                  onChange={(newDate) => {
                    setGameDate(newDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className={style.AddBusInput}>
                <p>שעת המשחק</p>
                <TimePicker
                  label=""
                  ampm={false}
                  value={gameTime}
                  onChange={(newTime) => {
                    setGameTime(newTime);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className={style.AddBusInput}>
                <p>שעת ההסעה</p>
                <TimePicker
                  label=""
                  ampm={false}
                  value={busTime}
                  onChange={(newTime) => {
                    setBusTime(newTime);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className={style.AddBusInput}>
                <p>נקודות איסוף</p>
                <FormGroup>
                  {stations.map((station) => (
                    <FormControlLabel
                      key={station.name}
                      control={
                        <Switch
                          checked={openStations[station.name]}
                          color={"secondary"}
                          onChange={onStationSwitch}
                          name={station.name}
                        />
                      }
                      label={station.name}
                    />
                  ))}
                </FormGroup>
              </div>
              <div className={style.AddBusInput}>
                <p>מס' נוסעים מקסימלי</p>
                <TextField
                  id="maxPassengers"
                  name="maxPassengers"
                  placeholder="הכנס מספר"
                  margin="dense"
                  autoComplete="off"
                  value={formik.values.maxPassengers}
                  error={
                    formik.touched.maxPassengers &&
                    Boolean(formik.errors.maxPassengers)
                  }
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.maxPassengers && formik.errors.maxPassengers
                  }
                />
              </div>
            </FormControl>

            <button className={style.SubmitButton} type={"submit"}>
              {prevBus ? "שמירת שינויים" : "יצירת הסעה"}
            </button>
          </form>
        </div>
      }
    </div>
  );
}

export default AddBus;
