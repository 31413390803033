import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export const useStations = () => {
  const [stations, setStations] = useState([]);

  useEffect(() => {
    const loadStations = async () => {
      const snapshot = await getDocs(collection(db, "Stations"));

      const newStations = [];
      for (const stationDoc of snapshot.docs) {
        const stationData = stationDoc.data();
        newStations.push({
          name: stationDoc.id,
          waze: stationData.waze,
          googleMaps: stationData.google_maps,
          index: stationData.index,
          defaultOpen: stationData.defaultOpen,
          timeToAdd: stationData.timeToAdd,
          price: stationData.price,
        });
      }
      newStations.sort((stationA, stationB) => stationA.index - stationB.index);
      setStations(newStations);
    };

    loadStations();
  }, [setStations]);

  return stations;
};
